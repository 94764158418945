'use client'
import type { LottieProps } from 'react-lottie-player';
// import Spinner from './Spinner';
// import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import dynamic from 'next/dynamic';
//     left: 0,
const DotLottieReact = dynamic(() => import('@lottiefiles/dotlottie-react').then(m => m.DotLottieReact), {
    loading: () => <p>Loading...</p>,
    ssr: false
})
// const LoadLottie = styled(Lottie, {
//     position: 'fixed',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     margin: 'auto',
//     maxHeight: '100vh',
//     maxWidth: '100vw',
//     height: '100vh',
// })
// const Lottie = dynamic(() => import('react-lottie-player'), {
//     loading: () => <p>Loading...</p>,
//     ssr: false
// })
export const PageLottie = ({ path, ...props }: { path: string } & LottieProps) => {
    // const [lottie, setLottie] = useState<object | null>(null);

    // useEffect(() => {
    //     import(`public/lotties/${path}.json`).then(lottie => setLottie(lottie))
    // }, []);
    // if (!lottie) return <Spinner />

    return (
        // @ts-ignore
        // <LoadLottie {...props} animationData={lottie} />
        <DotLottieReact
            className="fixed top-0 bottom-0 left-0 right-0 max-h-screen max-w-[100vw] h-screen m-auto object-center object-contain"
            src={`/lotties/${path}.lottie`}
            loop
            autoplay
        />
    );
};